import React from "react";
import { Row, Col } from "react-bootstrap";

const FormNew = (props) => {
  return (
    <Row>
      <Col md={12}></Col>
    </Row>
  );
};

export default FormNew;
